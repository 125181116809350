@import "../../utils/mixins";
@import "../../utils/colors";
@import "./dimensions";

$header-nav-select-color: black;
$header-nav-hover-color: black;

.dtm-header {
  width: 100%;
  height: $header-height-desktop;
  padding: 0 20px;
  color: black;
  z-index: 100;
  background-color: white;
  border-bottom: 1px solid $gray-a1;

  @include responsive(phone) {
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 5px;
    height: $header-height-mobile;
  }

  &__menu {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    @include responsive(phone-up) {
      display: none;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    @include responsive(phone) {
      margin: 0 auto;
    }

    & a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__content {
    max-width: 1280px;
    display: grid;
    gap: 1rem;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    height: 100%;
    margin: 0 auto;

    @include responsive(phone) {
      direction: rtl;
      grid-template-columns: 1fr max-content;
      gap: 0;
    }
  }

  &__nav {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    @include responsive(phone) {
      position: fixed;
      inset: $header-height-mobile 0 0 0;
      background-color: white;
      border-bottom: 1px solid $gray-a1;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 5px;
      flex-direction: column;
      transition: opacity 0.5s, visibility 0.5s, height 0.6s, font-size 0.3s;
      font-size: 10px;
      visibility: hidden;
      height: 0;
      opacity: 0;
    }

    &--open {
      visibility: visible !important;
      height: calc(100vh - #{$header-height-mobile});
      opacity: 1;
      font-size: 1.2rem;
    }

    &__item {
      color: $gray-a3;
      text-transform: uppercase;
      font-weight: 600;
      text-decoration: none;
      //transition: color 0.3s;
      position: relative;
      font-size: 1.15rem;

      &:before {
        content: attr(data-text);
        position: absolute;
        color: $header-nav-hover-color;
        top: 0;
        left: 0;
        width: 0%;
        overflow: hidden;
        transition: all 0.5s;
        box-shadow: 0 2px $header-nav-hover-color;
      }
      &:hover:before {
        width: 100%;
      }

      /* &:hover {
        text-decoration: overline;
      } */

      &--selected {
        //text-decoration: underline;
        color: $header-nav-select-color;
        box-shadow: 0 2px $header-nav-select-color;
      }
    }
  }
  &__left {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
  }
}
