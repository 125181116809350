$blue-pink: #4228d4;
$blue-a0: #090979;
$blue-a1: #a5d4dc;
$blue-a2: #f2f4f8;
$blue-a3: #d7e2e9;

$green-light: #00cc8e;

$gray-dark: #222222;
$gray-a0: #575965;
$gray-a1: #c3c4c8;
$gray-a2: #f8f8f6;
$gray-a3: #939498;

$page-bg: white;
$page-meta-bg: #f8f8f8;

$green-accepted: rgb(24, 116, 24);
$red-declined: rgb(204, 18, 18);
