@keyframes dtm-anim-popin {
  0% {
    transform-origin: left;
    transform: scaleX(0.2);
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-2 15:43:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacity-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
