@import "../../utils/mixins";

@mixin flexed {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.dtm-language-select-box {
  position: relative;
  direction: ltr;

  & a {
    text-decoration: none;
    color: black;
  }

  &__preview {
    @include flexed;
    padding: 0.5rem;
    justify-content: center;
    cursor: pointer;
  }

  &__dropdown {
    position: absolute;
    min-width: 150px;
    z-index: 5;
    border: 1px solid rgb(209, 209, 209);
    box-shadow: rgba(53, 53, 53, 0.192) 0px 2px 8px 0px;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    
    @include responsive(phone-up){
      right: 0;
    }
    @include responsive(phone){
      left: 0;
    }
    &__option {
      @include flexed;
      cursor: pointer;
      padding: 0.5rem 1rem;
      background-color: white;
      transition: background-color 0.25s;
      &:hover {
        background-color: rgb(214, 214, 214);
      }

      &--selected {
        background-color: rgb(235, 235, 235);
        font-weight: 600;
      }
    }
  }
}
