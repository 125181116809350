@import "./utils/colors";
@import "./utils/mixins";

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 1.5;
  @include responsive(phone-l) {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.m-t-s {
  margin-top: 0.25rem;
}

.m-t-m {
  margin-top: 0.5rem;
}

.m-t-l {
  margin-top: 0.75rem;
}

.m-t-xl {
  margin-top: 0.1rem;
}

.m-b-l {
  margin-bottom: 0.75rem;
}
