@keyframes dtm-anim-popin {
  0% {
    transform-origin: 0;
    opacity: 0;
    transform: scaleX(.2);
  }

  15% {
    opacity: 1;
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes opacity-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dtm-consent-box {
  color: #fff;
  z-index: 999;
  min-height: 25vh;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  max-width: 600px;
  background: linear-gradient(56deg, #191919b8 0%, #000000d9 100%);
  border-radius: 10px;
  padding: 1rem 2.3rem 1rem 1rem;
  font-size: .8rem;
  animation: scale-up-center .6s ease-out both;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  box-shadow: 0 3px 8px #0000003d;
}

.dtm-consent-box__header {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.dtm-consent-box__header__icon {
  font-size: 1.7rem;
}

.dtm-consent-box__btn-container {
  flex-direction: row-reverse;
  gap: .6rem;
  display: flex;
}

@media (max-width: 424px) {
  .dtm-consent-box__btn-container {
    flex-direction: column;
  }
}

.dtm-consent-box__link-container {
  gap: .5rem;
  font-size: .8em;
  display: flex;
}

.dtm-consent-box__link-container a {
  color: #939498 !important;
}

.dtm-consent-box__close-btn {
  color: #fff;
  margin: .25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.dtm-footer {
  color: #222;
  height: 250px;
  background-color: #f8f8f8;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .85rem;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.dtm-footer__sitemap {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: .5em;
  margin-bottom: 0;
  padding: 1em .2em;
  display: flex;
}

.dtm-footer__sitemap a {
  color: inherit;
  font-weight: lighter;
}

.dtm-header {
  width: 100%;
  height: 100px;
  color: #000;
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid #c3c4c8;
  padding: 0 20px;
}

@media (max-width: 767px) {
  .dtm-header {
    height: 64px;
    position: fixed;
    box-shadow: 0 3px 5px #0000001f;
  }
}

.dtm-header__menu {
  align-items: center;
  font-size: 1.5rem;
  display: flex;
}

@media (min-width: 768px) {
  .dtm-header__menu {
    display: none;
  }
}

.dtm-header__logo {
  align-items: center;
  display: flex;
}

@media (max-width: 767px) {
  .dtm-header__logo {
    margin: 0 auto;
  }
}

.dtm-header__logo a {
  color: inherit;
  text-decoration: none;
}

.dtm-header__content {
  max-width: 1280px;
  height: 100%;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  display: grid;
}

@media (max-width: 767px) {
  .dtm-header__content {
    direction: rtl;
    grid-template-columns: 1fr max-content;
    gap: 0;
  }
}

.dtm-header__nav {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media (max-width: 767px) {
  .dtm-header__nav {
    visibility: hidden;
    height: 0;
    opacity: 0;
    background-color: #fff;
    border-bottom: 1px solid #c3c4c8;
    flex-direction: column;
    font-size: 10px;
    transition: opacity .5s, visibility .5s, height .6s, font-size .3s;
    position: fixed;
    inset: 64px 0 0;
    box-shadow: 0 3px 5px #0000001f;
  }
}

.dtm-header__nav--open {
  height: calc(100vh - 64px);
  opacity: 1;
  font-size: 1.2rem;
  visibility: visible !important;
}

.dtm-header__nav__item {
  color: #939498;
  text-transform: uppercase;
  font-size: 1.15rem;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

.dtm-header__nav__item:before {
  content: attr(data-text);
  color: #000;
  width: 0%;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 2px #000;
}

.dtm-header__nav__item:hover:before {
  width: 100%;
}

.dtm-header__nav__item--selected {
  color: #000;
  box-shadow: 0 2px #000;
}

.dtm-header__left {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
}

.dtm-logo {
  filter: opacity(.8);
  color: #d9d9d9;
  text-shadow: -.0075em .0075em #f0f0f0, .005em .005em #999, .01em .01em #9e9e9e, .015em .015em #a3a3a3, .02em .02em #a8a8a8, .025em .025em #adadad, .03em .03em #b3b3b3, .035em .035em #b8b8b8;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  display: flex;
}

.dtm-flag {
  width: 1.3em;
  height: .858em;
  background: gray;
  border: 2px solid #fff;
}

.dtm-flag--de-DE {
  background: linear-gradient(#000 0% 32%, red 33% 66%, #fed70e 67% 100%);
}

.dtm-flag--pl-PL {
  background: linear-gradient(#efefef 0% 50%, red 50% 100%);
}

.dtm-language-select {
  align-items: center;
  gap: .3rem;
  display: flex;
  position: relative;
}

.dtm-language-select a {
  color: #000;
  text-decoration: none;
}

.dtm-language-select__option {
  cursor: pointer;
  align-items: center;
  gap: .3rem;
  font-size: .85rem;
  display: flex;
}

.dtm-language-select__option:hover {
  color: #3a3a3a;
}

.dtm-language-select__option--selected {
  font-weight: bold;
  text-decoration: underline !important;
}

.dtm-language-select-box {
  direction: ltr;
  position: relative;
}

.dtm-language-select-box a {
  color: #000;
  text-decoration: none;
}

.dtm-language-select-box__preview {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

.dtm-language-select-box__dropdown {
  min-width: 150px;
  z-index: 5;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 2px 8px #35353531;
}

@media (min-width: 768px) {
  .dtm-language-select-box__dropdown {
    right: 0;
  }
}

@media (max-width: 767px) {
  .dtm-language-select-box__dropdown {
    left: 0;
  }
}

.dtm-language-select-box__dropdown__option {
  cursor: pointer;
  background-color: #fff;
  align-items: center;
  gap: .5rem;
  padding: .5rem 1rem;
  transition: background-color .25s;
  display: flex;
}

.dtm-language-select-box__dropdown__option:hover {
  background-color: #d6d6d6;
}

.dtm-language-select-box__dropdown__option--selected {
  background-color: #ebebeb;
  font-weight: 600;
}

.sk-cube-grid {
  width: 100%;
  height: 100%;
  position: relative;
}

.sk-cube-grid__container {
  width: 40px;
  height: 40px;
  margin: auto;
  position: absolute;
  inset: 0;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  float: left;
  background-color: #333;
  animation: sk-cubeGridScaleDelay 1.3s ease-in-out infinite;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: .2s;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: .3s;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: .4s;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: .1s;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: .2s;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: .3s;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: .1s;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: .2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3d(1, 1, 1);
  }

  35% {
    transform: scale3d(0, 0, 1);
  }
}

.spinner {
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  display: inline-block;
}

.spinner .bounce1 {
  animation-delay: -.32s;
}

.spinner .bounce2 {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.dtm-simple-spinner {
  height: 1rem;
  animation: dtm-simple-spinner-rotate 2s linear infinite;
}

.dtm-simple-spinner__path {
  stroke: #fff;
  stroke-linecap: round;
  animation: dtm-simple-spinner-dash 1.5s ease-in-out infinite;
}

@keyframes dtm-simple-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dtm-simple-spinner-dash {
  0% {
    stroke-dasharray: 1 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124px;
  }
}

.dtm-app {
  scrollbar-gutter: stable;
  height: 100vh;
  max-width: 100vw;
}

@media (max-width: 767px) {
  .dtm-app {
    overflow: hidden;
  }
}

.dtm-main {
  height: calc(100vh - 100px);
}

@media (max-width: 767px) {
  .dtm-main {
    height: calc(100vh - 64px);
    margin-top: 64px;
  }
}

.dtm-page {
  background-color: #f8f8f8;
}

@media (max-width: 767px) {
  .dtm-page {
    max-height: 100%;
    overflow-y: auto;
  }
}

.dtm-page__content {
  max-width: 980px;
  min-height: calc(100vh - 250px);
  margin: 0 auto;
  padding: 20px 40px;
}

@media (max-width: 767px) {
  .dtm-page__content {
    min-height: calc(100vh - 214px);
    padding: 20px;
  }
}

.dtm-page__content-wrapper {
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #a3a3a3;
  position: relative;
  box-shadow: 0 3px 6px #00000017, 0 3px 6px #00000026;
}

.dtm-page__content-wrapper-footer-space {
  height: 250px;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

@media (max-width: 424px) {
  html {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.m-t-s {
  margin-top: .25rem;
}

.m-t-m {
  margin-top: .5rem;
}

.m-t-l {
  margin-top: .75rem;
}

.m-t-xl {
  margin-top: .1rem;
}

.m-b-l {
  margin-bottom: .75rem;
}

/*# sourceMappingURL=index.970a7a87.css.map */
