$main-col: #000000;

.dtm-logo {
  /* height: 100%;
  &__image {
    max-width: 160px;
    width: auto;
    height: auto;
  } */

  display: flex;
  align-items: center;
  justify-content: center;
  filter: opacity(0.8);

  color: mix(#fff, $main-col, 85%);
  font-weight: 900;
  text-shadow:
    -0.0075em 0.0075em 0 mix(#fff, $main-col, 94%),
    0.005em 0.005em 0 mix(#fff, $main-col, 60%),
    0.01em 0.01em 0 mix(#fff, $main-col, 62%),
    0.015em 0.015em mix(#fff, $main-col, 64%),
    0.02em 0.02em 0 mix(#fff, $main-col, 66%),
    0.025em 0.025em 0 mix(#fff, $main-col, 68%),
    0.03em 0.03em 0 mix(#fff, $main-col, 70%),
    0.035em 0.035em 0 mix(#fff, $main-col, 72%);
}
