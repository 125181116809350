@import "../../utils/colors";
@import "../../utils/mixins";
@import "../../utils/animations";

.dtm-consent-box {
  animation: scale-up-center 0.6s ease-out both;
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 10px;
  color: white;
  z-index: 999;
  padding: 1rem;
  min-height: 25vh;
  padding-right: 2.3rem;
  font-size: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: rgb(2, 0, 36);
  background: linear-gradient(56deg, rgb(25 25 25 / 72%) 0%, #000000d9 100%);
  border-radius: 10px;
  backdrop-filter: blur(6px);
  max-width: 600px;

  &__header {
    display: flex;
    gap: 1rem;
    align-items: center;

    &__icon {
      font-size: 1.7rem;
    }
  }

  &__btn-container {
    display: flex;
    gap: 0.6rem;
    flex-direction: row-reverse;

    @include responsive(phone-l) {
      flex-direction: column;
    }
  }

  &__link-container {
    display: flex;
    gap: 0.5rem;
    font-size: 0.8em;

    & a {
      color: $gray-a3 !important;
    }
  }

  &__close-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    margin: 0.25rem;
  }
}
