.dtm-flag {
  background: grey;
  width: 1.3em;
  height: calc(1.3em * 0.66);
  border: 2px solid white;

  &--de-DE {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 32%,
      rgba(255, 0, 0, 1) 33%,
      rgba(255, 0, 0, 1) 66%,
      rgba(254, 215, 14, 1) 67%,
      rgba(254, 215, 14, 1) 100%
    );
  }

  &--pl-PL {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, #efefef 0%, #efefef 50%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 1) 100%);
  }
}
