@mixin responsive($class) {
  @if $class == phone {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $class == tablet {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $class == desktop {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $class == phone-up {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $class == desktop-down {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $class == phone-l {
    @media (max-width: 424px) {
      @content;
    }
  } @else if $class == desktop-4k {
    @media (max-width: 1440px) {
      @content;
    }
  } @else {
    @warn "Valid parameters: [phone, phone-up, phone-l], [tablet], [desktop-down, desktop, desktop-4k]";
  }
}
