@import "../../utils/mixins";
@import "../../utils/colors";
@import "../../components/header/dimensions";
@import "../../components/footer/dimensions";

.dtm-app {
  scrollbar-gutter: stable;
  height: 100vh;
  max-width: 100vw;
  @include responsive(phone) {
    overflow: hidden;
  }
}

.dtm-main {
  height: calc(100vh - #{$header-height-desktop});
  @include responsive(phone) {
    margin-top: $header-height-mobile;
    height: calc(100vh - #{$header-height-mobile});
  }
}

.dtm-page {
  background-color: $page-meta-bg;
  @include responsive(phone) {
    overflow-y: auto;
    max-height: 100%;
  }

  &__content {
    padding: 20px 40px;
    max-width: 980px;
    margin: 0 auto;
    min-height: calc(100vh - 150px - #{$header-height-desktop});
    @include responsive(phone) {
      padding: 20px;
      min-height: calc(100vh - 150px - #{$header-height-mobile});
    }
  }

  &__content-wrapper {
    background-color: $page-bg;
    position: relative;
    z-index: 1;
    box-shadow: rgb(0 0 0 / 9%) 0px 3px 6px, rgb(0 0 0 / 15%) 0px 3px 6px;
    border-bottom: 1px solid #a3a3a3;
  }

  &__content-wrapper-footer-space {
    height: $footer-height;
  }
}
