@mixin flexed {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.dtm-language-select {
  position: relative;
  @include flexed;

  & a {
    text-decoration: none;
    color: black;
  }

  &__option {
    @include flexed;
    cursor: pointer;
    font-size: 0.85rem;
    &:hover {
      color: rgb(58, 58, 58);
    }

    &--selected {
      text-decoration: underline !important;
      font-weight: bold;
    }
  }
}
