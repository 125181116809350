@import "../../utils/mixins";
@import "../../utils/colors";
@import "./dimensions";

.dtm-footer {
  color: $gray-dark;
  background-color: $page-meta-bg;
  //background-color: $gray-dark;
  //color: white;
  height: $footer-height;
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  // positioning
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  &__sitemap {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1em 0.2em;
    margin-bottom: 0;
    gap: 0.5em;
    justify-content: center;
    & a {
      color: inherit;
      font-weight: lighter;
    }
  }
}
